<script setup lang="ts">
import type { AdministrationUnit } from "@gasparigit/omnia-sdk";
import { Check, ChevronsUpDown, XIcon } from "lucide-vue-next";
import { ref } from "vue";
import { cn } from "~/lib/utils";

const omniaSdk = useOmniaSdk();

const searchTerm = ref("");
const { data, refresh } = useAsyncData(
  async () => {
    try {
      return await omniaSdk.ipa.administrationUnits.search(searchTerm.value, { pagination: "classic", per_page: 30 });
    } catch (error) {}
  },
  {
    immediate: false,
  }
);

defineProps<{
  id?: string;
  name?: string;
  required?: boolean;
}>();

const bounceTimer = ref<NodeJS.Timeout>();

watch(searchTerm, () => {
  clearTimeout(bounceTimer.value);
  bounceTimer.value = setTimeout(() => {
    refresh();
  }, 500);
});

const open = ref(false);
const unitValue = defineModel<AdministrationUnit | null>("unit");
const ipaCode = defineModel<string | null>();

function selectUnit(selected: AdministrationUnit | null) {
  unitValue.value = selected;
  ipaCode.value = selected?.ipa_code;
}

const filteredList = computed(() => {
  if (unitValue.value) return [unitValue.value, ...(data.value?.data || [])];
  return data.value?.data || [];
});
</script>

<template>
  <div v-bind="$attrs" class="flex gap-2">
    <Popover v-model:open="open">
      <PopoverTrigger as-child>
        <Button variant="outline" role="combobox" :aria-expanded="open" class="w-full justify-between text-sm">
          {{ unitValue ? unitValue.name : "Seleziona un ente..." }}
          <ChevronsUpDown class="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent class="w-full p-0" style="width: var(--radix-popover-trigger-width)" align="start">
        <Command v-model:search-term="searchTerm">
          <CommandInput class="h-9" placeholder="Cerca un ente per nome o per codice IPA..." />
          <CommandEmpty class="px-3">Nessun ente trovato. Cerca un ente per nome o per codice IPA.</CommandEmpty>
          <CommandList>
            <CommandGroup>
              <CommandItem
                v-for="customer in filteredList"
                :key="customer.ipa_code"
                :value="customer"
                @select="
                  (ev) => {
                    if (typeof ev.detail.value === 'object') selectUnit(ev.detail.value as AdministrationUnit);
                    open = false;
                  }
                "
              >
                <div class="pr-2 flex flex-col gap-1">
                  <span>{{ customer.name }}</span>
                  <div>
                    <span class="font-semibold text-xs">{{ customer.ipa_code }} - {{ customer.municipality_location?.name }}</span>
                  </div>
                </div>
                <Check :class="cn('ml-auto h-4 w-4', unitValue?.ipa_code === customer.ipa_code ? 'opacity-100' : 'opacity-0')" />
              </CommandItem>
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
    <Button @click="selectUnit(null)" v-if="unitValue" variant="ghost" size="icon"><XIcon class="h-4 w-4 opacity-50" /></Button>
  </div>
</template>
